import { Stack, TextField, Typography } from "@mui/material";
import { FC } from "react";
import returnGoodsManualStore from "../../../../../store/returnGoodsManual.store";

export const SendingManual: FC<{ isEdit: boolean }> = ({ isEdit }) => {
  return (
    <Stack spacing={2}>
      <Stack>
        <Typography fontWeight={"bold"}>Общие положения</Typography>
        <TextField
          multiline
          value={returnGoodsManualStore.general}
          variant={isEdit ? "outlined" : "standard"}
          onChange={(e) => returnGoodsManualStore.setGenereal(e.target.value)}
          InputProps={{
            readOnly: !isEdit,
            disableUnderline: !isEdit,
          }}
          sx={{
            "& .MuiInputBase-input.Mui-disabled": {
              color: "black",
            },
            padding: 0,
          }}
        />
      </Stack>
      <Stack>
        <Typography fontWeight={"bold"}>
          Последовательность действий для отправки образцов блогерам
        </Typography>
        <TextField
          multiline
          value={returnGoodsManualStore.sequenceOfActions}
          variant={isEdit ? "outlined" : "standard"}
          onChange={(e) => returnGoodsManualStore.setSequenceOfActions(e.target.value)}
          InputProps={{
            readOnly: !isEdit,
            disableUnderline: !isEdit,
          }}
          sx={{
            "& .MuiInputBase-input.Mui-disabled": {
              color: "black",
            },
          }}
        />
      </Stack>
    </Stack>
  );
};
