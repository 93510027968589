import { makeAutoObservable } from "mobx";

class ReturnGoodsManualStore {
  general: string =
    "- Образцы товаров отправляем только для каналов, у которых более 300к просмотров. \n- Согласовываем отправку образцов с зам.рук. отдела маркетинга. \n- Образцы предоставляются  – с возвратом, предупреждаем об этом автора.";

  sequenceOfActions: string =
    "1.Пишем руководителю РКЦ - Алексей 7 904 484-79-99. \n2.Отправляемый продукт модель/цвет/количество (Как правило отправляем 2 пары образцов разного цвета). \n3.ФИО получателя. \n4.Адрес доставки (Уточняем это  Пункт Выдачи заказов или адрес проживания). \n5.Номер телефона получателя. \n6.Сообщаем блогеру, что обязательно необходимо сохранить вложенный в посылку товарный чек! Сложить и хранить в коробке с наушниками. При заборе образца – вложить в пакет.";

  listToWrite: string =
    "- Номер заказа по которому отправлялись образцы. \n- ФИО на отдельном листе. \n- ПВЗ или адрес  забора образцов (нужно учитывать, что при забора / доставки с ПВЗ стоимость дешевле на 30-40%).";

  algoritmOfActions: string =
    "1.Ставит соответствующие признаки в CRM маркетинга о заборе образцов + заполняет реестр отправки/забора образцов блогеров https://docs.google.com/spreadsheets/d/1_azbHo9ZoST4tBTh6BN2Jwv_M3CJTe3aw7-Xj4SiG-8/edit?gid=0#gid=0 *и отправляет возвратный трек блогеру, для сдачи товара в ТК. \n2.Светлана в ЛК СДЭК формирует трек и вносит его в CRM Возвратный трек номер и вносит данный трек в реестр отправки/забора образцов блогеров ссылка. (Свете тут надо посмотреть как  работает интеграция и как по статусам переходит заказ). \n3.Светлана раз в месяц проверяет по реестру возвратные накладные от блогеров на предмет доставлен/не доставлен на склад Тюмени. \n4.В случае если блоге по треку не отправил образцы, то пишет об этом отделу Рекламы, ответственному менеджеру, чтобы они связались с блогером по этому вопросу";
  constructor() {
    makeAutoObservable(this);
  }

  setGenereal(value: string) {
    this.general = value;
  }

  setSequenceOfActions(value: string) {
    this.sequenceOfActions = value;
  }

  setListToWrite(value: string) {
    this.listToWrite = value;
  }

  setAlgoritmOfActions(value: string) {
    this.algoritmOfActions = value;
  }
}

export default new ReturnGoodsManualStore();
