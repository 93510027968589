import { Stack, TextField, Typography } from "@mui/material";
import { FC } from "react";
import returnGoodsManualStore from "../../../../../store/returnGoodsManual.store";

export const ReturnManual: FC<{ isEdit: boolean }> = ({ isEdit }) => {
  return (
    <Stack spacing={2}>
      <Stack>
        <Typography fontWeight={"bold"}>
          Отдел Маркетинга пишет Светлане Михеевой, что нужно вернуть образцы:
        </Typography>
        <TextField
          multiline
          value={returnGoodsManualStore.listToWrite}
          variant={isEdit ? "outlined" : "standard"}
          onChange={(e) => returnGoodsManualStore.setListToWrite(e.target.value)}
          InputProps={{
            readOnly: !isEdit,
            disableUnderline: !isEdit,
          }}
          sx={{
            "& .MuiInputBase-input.Mui-disabled": {
              color: "black",
            },
            padding: 0,
          }}
        />
      </Stack>
      <Stack>
        <Typography fontWeight={"bold"}>Алгоритм действий</Typography>
        <TextField
          multiline
          value={returnGoodsManualStore.algoritmOfActions}
          variant={isEdit ? "outlined" : "standard"}
          onChange={(e) => returnGoodsManualStore.setAlgoritmOfActions(e.target.value)}
          InputProps={{
            readOnly: !isEdit,
            disableUnderline: !isEdit,
          }}
          sx={{
            "& .MuiInputBase-input.Mui-disabled": {
              color: "black",
            },
          }}
        />
      </Stack>
    </Stack>
  );
};
