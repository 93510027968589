import {FC} from 'react'
import {ITable} from './models/ITable'
import {Avatar, Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography,} from '@mui/material'
import {List, Lock, PriorityHigh, Settings} from '@mui/icons-material'
import {TableSort} from './ui/TableSort'
import {TableCustomFooter} from './ui/TableCustomFooter'
import {ThemeAddButtonDialog} from "./ui/Columns/ThemeAddButtonDialog/ThemeAddButtonDialog";
import {PositioningAddButtonDialog} from "./ui/Columns/PositioningAddButtonDialog/PositioningAddButtonDialog";
import { CustomIconButton } from '../UI/Buttons/CustomIconButton/CustomIconButton'

export const TableDefault:FC<ITable> = (props) => {
  const {
    tableRow,
    children,
    settingsOnclick,
    showBurgerCell = false,
    totalItems,
    showFooter = false,
    onChaneToggleButton,
    toggleValues,
    toggleItems,
    onExportExcel,
    maxHeight,
    isShowSort = false,
    sortHandler,
    currentSortType,
    onChangeCurrentSortType,
    maxWidth,
    showScrollButton = false,
    showDeleteIcon = false,
    hideBorder,
    showBorderRadius = true,
    onChangePage,
    totalCount,
    paginateStep,
    page,
    widthCell,
    showCheckboxCell = false,
    stickyHeader = true,
    sx,
    showLastCell,
    isAccordion,
    talbeHeaderColor = '#f2f3f5',
    showAgreeIconsCell,
    tableId,
    disabledExportExcelBtn,
    tableHeaderId,
    firstCellIcon,
    hideUrlSortCode,
    showBoxShadow,
    showManuals
  } = props

  return (
    <Box
      sx={{
        position:'relative',
        maxWidth:maxWidth ? maxWidth : 'calc(100vw - (70px + (16px * 2)))',
        overflow:'auto',
        pt:settingsOnclick ? 2 : 0,
      }}
    >
      {settingsOnclick &&
        <Tooltip title='Настройки' placement='right'>
          <Box sx={{position:'absolute', top:'0px', left:'-3px', zIndex:12, display:'inline-block'}}>
            <CustomIconButton onClick={settingsOnclick}>
              <Settings />
            </CustomIconButton>
          </Box>
        </Tooltip>
      }
      <TableContainer
        id={tableId}
        component={Paper}
        elevation={0}
        sx={{
          border:hideBorder ? 'none' : '1px solid #e0e0e0',
          maxHeight:maxHeight && maxHeight,
          borderBottom:'none',
          borderBottomLeftRadius:showFooter || !showBorderRadius ? '4px': '20px',
          borderBottomRightRadius:showFooter || !showBorderRadius ? '4px': '20px',
          borderTopLeftRadius:showBorderRadius ? '20px' : '4px',
          borderTopRightRadius:showBorderRadius ? '20px' : '4px',
          transition:'all ease .3s',
          boxShadow:showBoxShadow ? `0px 0px 8px ${showBorderRadius ? '-2px' : '0px'} rgba(34, 60, 80, 0.2)` : undefined,
          ...sx
        }}
      >
        <Table stickyHeader={stickyHeader}>
          <TableHead id={tableHeaderId}>
            <TableRow>
              {showCheckboxCell && <TableCell align='left' sx={{width:'37px', p:1, background:talbeHeaderColor}}></TableCell>}
              {showBurgerCell &&
                <TableCell align='left' sx={{width:'37px', p:1, background:talbeHeaderColor}}>
                  <List sx={{display:'flex'}}/>
                </TableCell>
              }
              {showAgreeIconsCell && 
                <TableCell align='left' sx={{p:1,pl:0, background:talbeHeaderColor}}><PriorityHigh fontSize='small' sx={{display:'flex',ml:-0.5}}/></TableCell>
              }
              {showDeleteIcon &&
                <TableCell align='left' sx={{width:'37px', p:1, background:talbeHeaderColor}}>
                  <List sx={{display:'flex'}}/>
                </TableCell>
              }
              {firstCellIcon &&
                <TableCell sx={{p: 0, pl: 0, background: talbeHeaderColor, ...sx}} onClick={e => e.stopPropagation()}></TableCell>
              }
              {tableRow.map((item, ind)=>{
                if(item) {
                  return <TableCell
                      key={ind + item.code}
                      sx={{
                        p:1, 
                        width:widthCell ? widthCell : 'auto', 
                        maxWidth: item.maxWidth, 
                        minWidth: item.minWidth, 
                        background:talbeHeaderColor
                      }}
                    >
                      {isShowSort && item.isSort
                        ? <TableSort
                            type={item.code}
                            onClick={sortHandler ? sortHandler : ()=>''}
                            currentSortType={currentSortType ? currentSortType : ''}
                            onChangeCurrentSortType={onChangeCurrentSortType ? onChangeCurrentSortType : ()=>''}
                            hideUrlSortCode={hideUrlSortCode}
                          >
                            <Typography sx={{fontWeight:'bold', display:'inline-flex', verticalAlign:'super', whiteSpace:'wrap'}}>{item.title}</Typography>
                            {item.immutable && <Lock sx={{ml:1}}/>}
                            {item.isTheme && <ThemeAddButtonDialog/>}
                          </TableSort>
                        : <Box sx={{display:'flex', alignItems:'center'}}>
                            {item.columnTitleiconSrc && <Avatar sx={{height:20, width:20, mr:0.5}} src={item.columnTitleiconSrc}/>}
                            <Typography sx={{fontWeight:'bold', display:'inline-flex', verticalAlign:'super', whiteSpace:'wrap'}}>{item.title}</Typography>
                            {item.immutable && <Lock sx={{ml:1}}/>}
                            {item.isTheme && <ThemeAddButtonDialog/>}
                            {item.isPositioning && <PositioningAddButtonDialog/>}
                          </Box>
                      }
                    </TableCell>
                  } else return
                })
              }
            {showLastCell && <TableCell align='right' sx={{p:1, background:talbeHeaderColor}}></TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {children}
          </TableBody>
        </Table>
      </TableContainer>
        {showFooter &&
          <TableCustomFooter
            hideBorder={hideBorder}
            onChaneToggleButton={onChaneToggleButton}
            onExportExcel={onExportExcel}
            toggleItems={toggleItems}
            toggleValues={toggleValues}
            totalItems={totalItems}
            onChangePage={onChangePage}
            totalCount={totalCount}
            paginateStep={paginateStep}
            page={page}
            disabledExportExcelBtn={disabledExportExcelBtn}
            showBoxShadow={showBoxShadow}
            showManuals={showManuals}
          />
        }
    </Box>
  )
}