import {
  Pagination,
  PaginationItem,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { FC, useEffect, useState } from "react";
import tableRowsStore from "../../../store/tableRows.store";
import { CustomButton } from "../../UI/Buttons/CustomButton/CustomButton";
import { ITableFooter } from "../models/ITableFooter";
import { Manuals } from "./Manuals/Manuals";

const buttonInteractionStyles = {
  "&:hover": {
    color: "#7C64D9",
    background: "#E2DBFF",
  },
  "&:active": {
    color: "#503D9D",
    background: "#E2DBFF",
  },
};

const buttonActiveInteractionStyles = {
  backgroundColor: "#E2DBFF",
  color: "#503D9D",
  "&:hover": {
    bgcolor: "#E2DBFF",
  },
};

export const TableCustomFooter: FC<ITableFooter> = observer((props) => {
  const {
    hideBorder,
    toggleValues,
    onChaneToggleButton,
    totalItems,
    onExportExcel,
    toggleItems,
    onChangePage,
    totalCount,
    paginateStep,
    page,
    disabledExportExcelBtn,
    showBoxShadow,
    showManuals,
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    if (onChangePage) {
      onChangePage(value);
    }
  };

  useEffect(() => {
    return () => tableRowsStore.resetCheckedRows();
  }, []);

  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
      sx={{
        border: hideBorder ? "none" : "1px solid #e0e0e0",
        borderTop: hideBorder ? "1px solid #e0e0e0" : "none",
        p: 1,
        mt: "-1px",
        background: "#fff",
        borderBottomLeftRadius: "4px",
        borderBottomRightRadius: "4px",
        overflow: "auto",
        boxShadow: showBoxShadow ? "0px 4px 10px -2px rgba(34, 60, 80, 0.2)" : undefined,
      }}>
      <Stack
        direction={"row"}
        sx={{
          justifyContent: "space-between",
          overflow: "auto",
          minWidth: "650px",
          width: "100%",
        }}>
        <Stack direction={"row"} alignItems={"center"} spacing={1}>
          <Typography sx={{ display: { xs: "none", md: "inline-block" } }}>
            Кол-во строк:
          </Typography>
          <ToggleButtonGroup value={toggleValues} onChange={onChaneToggleButton} exclusive>
            {toggleItems?.map((item) => (
              <ToggleButton
                key={item}
                value={item}
                sx={{
                  p: 1,
                  "&&": {
                    ...(toggleValues?.includes(item)
                      ? buttonActiveInteractionStyles
                      : buttonInteractionStyles),
                  },
                }}>
                {item}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
          <Typography>Всего: {totalCount}</Typography>
          {tableRowsStore.checkedRowsCounter && (
            <Typography>Выделено: {tableRowsStore.checkedRowsCounter}</Typography>
          )}
        </Stack>
        <Stack direction={"row"} alignItems={"center"} spacing={1}>
          {showManuals && <Manuals />}
          {onExportExcel && (
            <CustomButton
              variant="outlined"
              onClick={onExportExcel}
              disabled={disabledExportExcelBtn}>
              Выгрузить в Excel
            </CustomButton>
          )}
          {totalCount && paginateStep ? (
            <Pagination
              page={page}
              onChange={handleChange}
              count={Math.ceil(totalCount / paginateStep)}
              size="small"
              renderItem={(item) => (
                <PaginationItem
                  sx={{
                    "&&": {
                      ...(item.selected ? buttonActiveInteractionStyles : buttonInteractionStyles),
                    },
                  }}
                  {...item}
                />
              )}
            />
          ) : null}
        </Stack>
      </Stack>
    </Stack>
  );
});
