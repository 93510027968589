import { Dialog, Paper, Stack, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { FC, useState } from "react";
import { CustomIconButton } from "../../../UI/Buttons/CustomIconButton/CustomIconButton";
import { Cancel, Description } from "@mui/icons-material";
import { CustomButton } from "../../../UI/Buttons/CustomButton/CustomButton";
import userStore from "../../../../store/user.store";
import { ALL_ROLES } from "../../../../shared/rules/interface/EnumRights";
import { SendingManual } from "./ui/SendingManual";
import { ReturnManual } from "./ui/ReturnManual";

export const Manuals: FC = observer(() => {
  const [isOpen, setIsOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isProductSending, setisProductSending] = useState(false);
  const [isProductReturns, setIsProductReturns] = useState(false);

  const handleOpenModalWithProductSending = () => {
    setIsOpen(true);
    setisProductSending(true);
  };

  const handleOpenModalWithProductReturns = () => {
    setIsOpen(true);
    setIsProductReturns(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    setIsEdit(false);
    setIsProductReturns(false);
    setisProductSending(false);
  };

  const handleSubmit = () => {
    console.log("sad");
  };

  const editRigths =
    userStore.user?.role === ALL_ROLES.PRE_HEAD_MANAGER || userStore.user?.role === ALL_ROLES.ADMIN;

  return (
    <>
      <Stack direction={"row"} spacing={2}>
        <Stack direction={"row"} spacing={0.5} alignItems={"center"}>
          <CustomIconButton color="primary" onClick={handleOpenModalWithProductSending}>
            <Description />
          </CustomIconButton>
          <Typography>Мануал отправки</Typography>
        </Stack>
        <Stack direction={"row"} spacing={0.5} alignItems={"center"}>
          <CustomIconButton color="primary" onClick={handleOpenModalWithProductReturns}>
            <Description />
          </CustomIconButton>
          <Typography>Мануал возврата</Typography>
        </Stack>
      </Stack>
      <Dialog open={isOpen} onClose={handleCloseModal}>
        <Paper
          sx={{
            padding: 3,
            display: "flex",
            flexDirection: "column",
            rowGap: 2,
            width: "600px",
          }}>
          <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
            <Typography fontWeight={"bold"} fontSize={"large"}>
              {isProductSending
                ? "Регламент отправки блогеру образцов товаров"
                : isProductReturns
                ? "Регламент возврата от блогера образцов товаров"
                : ""}
            </Typography>
            <CustomIconButton onClick={handleCloseModal}>
              <Cancel />
            </CustomIconButton>
          </Stack>
          {isProductSending ? (
            <SendingManual isEdit={isEdit} />
          ) : isProductReturns ? (
            <ReturnManual isEdit={isEdit} />
          ) : null}
          {editRigths && (
            <>
              {isEdit ? (
                <Stack direction={"row"} spacing={4} alignSelf={"center"}>
                  <CustomButton onClick={() => setIsEdit(false)}>Отмена</CustomButton>{" "}
                  {/* нужно будет после привязки бэка возвращать на исходное значение */}
                  <CustomButton variant="contained" onClick={handleSubmit}>
                    Сохранить
                  </CustomButton>{" "}
                  {/* submit */}
                </Stack>
              ) : (
                <CustomButton
                  sx={{ maxWidth: "fit-content", alignSelf: "center" }}
                  variant="contained"
                  onClick={() => setIsEdit(true)}>
                  Редактировать
                </CustomButton>
              )}
            </>
          )}
        </Paper>
      </Dialog>
    </>
  );
});
